import React, { useState } from "react"
import { Helmet } from 'react-helmet'

import "../styles/global.css"
import Navbar from "./Navbar"
import Burger from "./Burger"
import Menu from "./Menu"

import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt, faFileDownload } from "@fortawesome/free-solid-svg-icons"

library.add(fab, faExternalLinkAlt, faFileDownload)

export default function Layout({ children }) {
  const [open, setOpen] = useState(false)
  return (
    <div className="div">
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Navbar />
      <main className="layout">
        <Burger open={open} setOpen={setOpen} />
        <Menu open={open} setOpen={setOpen} />
        <div className="content">{children}</div>
      </main>
      <footer>
        <p>
          &#169; Patrick Oldman 2021{" "}
          <a href="https://github.com/Oldman10000" target="_blank" rel="noreferrer" title="Patrick Oldman GitHub">
            <FontAwesomeIcon icon={["fab", "github"]} />
          </a>{" "}
          <a href="https://www.linkedin.com/in/patrick-oldman/" target="_blank" rel="noreferrer" title="Patrick Oldman LinkedIn">
            <FontAwesomeIcon icon={["fab", "linkedin"]} />
          </a>{" "}
          | Created using{" "}
          <strong>
            <a href="https://www.gatsbyjs.com/" target="_blank" rel="noreferrer">
              Gatsby
            </a>
          </strong>
        </p>
      </footer>
    </div>
  )
}
