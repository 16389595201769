import React from 'react'
import * as styles from "../styles/burger.module.css"

export default function Burger({ open, setOpen }) {
  return (
    <div className={styles.burger} open={open} onClick={() => setOpen(!open)}>
      <div className={`${open ? styles.first : ''}`}/>
      <div className={`${open ? styles.second : ''}`}/>
      <div className={`${open ? styles.third : ''}`}/>
    </div>
  )
}
