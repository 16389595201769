import React from "react"
import { Link } from "gatsby"
import * as styles from "../styles/menu.module.css"

export default function Menu({ open }) {
  return (
    <nav className={`${styles.menu} ${open ? styles.open : ''}`} open={open}>
      <Link to="/" activeClassName={styles.active}>
        Home
      </Link>
      <Link to="/about" activeClassName={styles.active}>
        About Me
      </Link>
      <Link to="/projects" activeClassName={styles.active}>
        Portfolio
      </Link>
    </nav>
  )
}
