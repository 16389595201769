import { Link } from 'gatsby'
import React from 'react'
import * as styles from "../styles/navbar.module.css"

export default function Navbar() {

  return (
    <nav className={styles.largeNav}>
      <div className={styles.links}>
        <Link to="/" activeClassName={styles.active}>Home</Link>
        <Link to="/about" activeClassName={styles.active}>About Me</Link>
        <Link to="/projects" activeClassName={styles.active}>Portfolio Projects</Link>
      </div>
    </nav>
  )
}
